.abandoned-cart-table {
  padding-bottom: 30px;

  table {
    border-color: var(--border-color);
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    padding: 5px;
    border-color: var(--border-color);
    text-align: left;
  }

  .inner-table {
    padding: 10px;

    a {
      display: block;
      padding: 5px;
      margin: -5px;
    }
  }
}

.abandoned-cart-checkbox {
  padding-bottom: 15px;

  input {
    cursor: pointer;
  }

  .input-with-label .label {
    padding-bottom: 0;
    cursor: pointer;
  }
}

